import React from "react";
import { Helmet } from "react-helmet";
import { Erbjudande } from "../home/filter/Erbjudande";

const erbjudande = () => {
  return (
    <>
      <Helmet>
        <title>Paket Erbjudanden – Köp Potensmedel som Viagra och Cialis Online från Sverige!</title>
        <meta 
          name="description" 
          content="Upptäck förmånliga paket på potensmedel som Viagra, Cialis och Kamagra. Beställ enkelt online från Sverige med snabb och diskret leverans. Spara pengar idag!" 
        />
        <meta 
          name="keywords" 
          content="potensmedel paket, köpa Viagra paket, köpa Cialis paket, Kamagra erbjudanden, potensmedel online, potensmedel utan recept, snabba leveranser potensmedel, paket erbjudanden potensmedel"
        />
        <link rel="canonical" href="https://viagrashop.se/erbjudande" />

        {/* Open Graph */}
        <meta property="og:title" content="Paket Erbjudanden – Köp Potensmedel som Viagra och Cialis Online!" />
        <meta 
          property="og:description" 
          content="Få bästa erbjudanden på potensmedel som Viagra, Cialis och Kamagra. Spara pengar med våra paket och njut av snabb, diskret leverans från Sverige." 
        />
        <meta property="og:url" content="https://viagrashop.se/erbjudande" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:title" content="Paket Erbjudanden – Viagra och Cialis Online med Snabb Leverans!" />
        <meta 
          name="twitter:description" 
          content="Beställ paket med potensmedel som Viagra och Cialis online. Snabb och diskret leverans direkt från Sverige. Missa inte våra specialerbjudanden!" 
        />
        <meta name="twitter:url" content="https://viagrashop.se/erbjudande" />
        <meta name="twitter:card" content="summary" />

        {/* X-Content-Type-Options */}
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      </Helmet>
      <main>
        <article className="main-container">
          <section className="text-center">
          </section>
          <section className="container-section-products">
            <Erbjudande />
          </section>
        </article>
      </main>
    </>
  );
}

export default erbjudande;
